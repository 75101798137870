#mentionslegales {
  width: 95%;
}

#mentionslegales p {
  font-size: 18px;
  font-family: "montserrat", sans-serif;
}

#mentionslegales strong {
  font-family: "montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700 !important;
  color: #cf9a52;
}
