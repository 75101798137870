.car-title {
  color: black;
  font-weight: 600 !important;
  font-family: "Montserrat-Regular";
  font-size: 18px;
}

.cardbody {
  box-shadow: none !important;
  border: none !important;
}

.catgory-miles {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Montserrat-Regular";
}

.miels-body {
  box-shadow: none !important;
}

.label-product {
  transition: 0.5s;
  cursor: pointer;
}

.label-product:hover {
  color: #cf9a52;
}

.label-miels {
  transition: 0.5s;
  cursor: pointer;
}

.label-miels:hover {
  color: #cf9a52;
}

.radio-item-miel [type="radio"] {
  display: none;
}
.radio-item-miel [type="radio"]:checked ~ label {
  color: #cf9a52;
}

.accueil-menu:hover {
  color: #cf9a52 !important;
}

.nav-miels {
  margin-left: 10px !important;
  font-family: "Montserrat-Regular";
}

@media only screen and (min-width: 590px) and (max-width: 1120px) {
  .car-title {
    font-size: 16px;
  }

  .catgory-miles {
    font-size: 13px;
  }

  .nav-miels a {
    font-size: 17px;
  }

  .miels-body {
    
    width: 250px  !important;
  }
}
