#container-contact {
    background-color: #f9f9f7;
    margin-top: 0px;
    margin-bottom: 50px;
}

#container-contact h1 {
    color: #c49c63;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-family: 'prata', serif;
    font-size: 30px;
}

#container-contact h3 {
    color: #343535;
    font-size: 25px;
    font-family: "prata", sans-serif;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 60px;
}

#container-contact .form-content .label-form-contact{
    color: #191a1a;
    font-size: 17px;
    font-family: "montserrat", sans-serif;
}

#container-contact .form-content .input-form-contact {
    background-color: #f0efef;
    color: #191a1a;
    border:none;
    text-align: left;
    font-size: 17px;
    font-family: "montserrat", sans-serif;
    padding-top: 25px;
    padding-bottom: 25px;
}

#container-contact .form-content .input-form-contact:focus {
    background-color: #fff;
    border: 1px solid #b9771a;
    color: #191a1a;
    border-color: #b9771a;
    box-shadow: none !important;
    font-size: 17px;
    font-family: "montserrat", sans-serif;

}

#container-contact .form-content .input-form-contact::placeholder {
    text-align: left;
    color: #191a1a;
    font-size: 17px;
    font-family: "montserrat", sans-serif;
}

#container-contact .form-content .form-check-input[type=checkbox] {
    border: 1px solid #b9771a;
    border-color: #b9771a;
    box-shadow: none !important;
    font-family: "montserrat", sans-serif;
}

#container-contact .form-content .form-check-input[type=checkbox]:checked {
    background-color: #b9771a;
    border-color: #b9771a;
    box-shadow: none !important;
}

#container-contact .form-content .form-check-label {
    color: #191a1a;
    font-size: 15px;
    font-family: "montserrat", sans-serif;

}

#container-contact .form-content .btn-contact-Envoyer {
    background-color: #c49c63;
    color: #fff;
    font-size: 17px;
    font-family: "montserrat", sans-serif;
    box-shadow: none !important;
    margin-top: 50px;
}

#container-contact .form-content .btn-contact-Envoyer:hover {
    background-color: #1a1919;
    color: #fff;
}



