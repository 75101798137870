#filtercoffrets .cof-title {
  color: black;
  font-weight: 600 !important;
  font-family: "Montserrat-Regular";
  font-size: 18px;
  margin-left: -20px;
}

#filtercoffrets .coffre-miles {
  font-size: 16px;
  font-family: "Montserrat-Regular";
}

#filtercoffrets .label-cofrets {
  transition: 0.5s;
  cursor: pointer;
}

#filtercoffrets .label-cofrets:hover {
  color: #cf9a52;
}

#filtercoffrets .label-cofrets:focus {
  color: #cf9a52;
}

.radio-item-cof [type="radio"] {
  display: none;
}

.radio-item-cof [type="radio"]:checked ~ label {
  color: #cf9a52;
}

#filtercoffrets .label-cofrets.selected {
  color: #cf9a52;
}

#filtercoffrets .menu-cof:hover {
  color: #cf9a52 !important;
}

.nav-coff {
  margin-left: 13px !important;
  font-family: "Montserrat-Regular";
}

@media only screen and (min-width: 590px) and (max-width: 1120px) {
  #filtercoffrets .cof-title {
    font-size: 16px;
  }

  #filtercoffrets .coffre-miles {
    font-size: 13px;
  }

  #filtercoffrets .nav-coff a {
    font-size: 17px;
  }

  .cof-body {
    width: 250px !important;
  }
}
