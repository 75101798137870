body{
  background-color: white;
}

@font-face{
  font-family: 'Bodoni';
  src: url('../src/assets/fonts/Bodoni.ttf');
}

.Bodoni{
  font-family: 'Bodoni';
}

@font-face{
  font-family: 'Montserrat-Regular';
  src: url('../src/assets/fonts/Montserrat-Regular.ttf');
}

.Montserrat-Regular{
  font-family: 'Montserrat-Regular';
}

@font-face{
  font-family: 'Montserrat-Medium';
  src: url('../src/assets/fonts/Montserrat-Medium.ttf');
}

.Montserrat-Medium{
  font-family: 'Montserrat-Medium';
}

@font-face{
  font-family: 'Prata-Regular';
  src: url('../src/assets/fonts/Prata-Regular.ttf');
}

.Prata-Regular{
  font-family: 'Prata-Regular';
}

@font-face{
  font-family: 'Montserrat-Bold';
  src: url('../src/assets/fonts/Montserrat-Bold.ttf');
}

.Montserrat-Bold{
  font-family: 'Montserrat-Bold';
}

@font-face{
  font-family: 'Poppins-Regular';
  src: url('../src/assets/fonts/Poppins-Regular.ttf');
}

.Poppins-Regular{
  font-family: 'Poppins-Regular';
}

@font-face{
  font-family: 'Roboto-Regular';
  src: url('../src/assets/fonts/Roboto-Regular.ttf');
}

.Roboto-Regular{
  font-family: 'Roboto-Regular';
}

@font-face{
  font-family: 'Roboto-Bold';
  src: url('../src/assets/fonts/Roboto-Bold.ttf');
}

.Roboto-Bold{
  font-family: 'Roboto-Bold';
}

@font-face{
  font-family: 'SourceSansPro-Regular';
  src: url('../src/assets/fonts/SourceSansPro-Regular.otf');
}

.SourceSansPro-Regular{
  font-family: 'SourceSansPro-Regular';
}

@font-face{
  font-family: 'HelloParisSerif-Regular';
  src: url('../src/assets/fonts/HelloParisSerif-Regular.ttf');
}

.HelloParisSerif-Regular{
  font-family: 'HelloParisSerif-Regular';
}

@font-face{
  font-family: 'BodoniModa';
  src: url('../src/assets/fonts/BodoniModa.ttf');
}

.BodoniModa{
  font-family: 'BodoniModa';
}
