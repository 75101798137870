#block_coffrets-pack .composer__intro-text {
  text-align: left;
  font-family: "montserrat", sans-serif;
  font-size: 1rem;
  color: #cf9a52;
  font-weight: 500;
}

#block_coffrets-pack .sotitlecof {
  font-family: "Prata", serif;
  color: #000;
  font-size: 18px;
  font-weight: 500 !important;
}

#block_coffrets-pack .block__title {
  text-align: center;
  display: block;
  font-weight: 400;
  font-family: "montserrat", sans-serif;
  line-height: 20px;
}

#block_coffrets-pack .composer__checkbox {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 200px;
  color: #ffffff;
  background: #ffffff;
  border: 1px solid #000;
  text-align: center;
  margin: auto auto 0;
  line-height: 30px;
}

#block_coffrets-pack .composer__checkbox.active {
  background: #cc9c57;
  color: white;
  border-color: #cc9c57 !important;
  width: 30px;
  height: 30px;
  line-height: 28px;
  bottom: 5px;
}

#block_coffrets-pack .icon-check:before {
  content: "\2713";
  font-size: 17px;
}


#block_coffrets-pack .card-image {
  position: relative;
  overflow: hidden;
}

#block_coffrets-pack .card-image img {
  border-radius: 2% !important;
  transition: transform 0.7s ease;
}

#block_coffrets-pack .card-image:hover img {
  transform: scale(1.1);
}

.composer-block__item.active .composer__checkbox .icon-check {
  visibility: visible;
}

.composer-block__item .icon-check {
  visibility: hidden;
}

#block_coffrets-pack .btnconti:hover {
  background-color: #fff;
  color: #c49c63;
}

#block_coffrets-pack .btnconti {
  margin-top: 40px !important;
  color: #ffffff;
  background-color: #cc9c57;
  border: 2px solid #cc9c57;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  font-size: 20px;
}

#block_coffrets-pack .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#block_coffrets-pack .clickable-image {
  cursor: pointer;
}


@media screen and (max-width: 991px) {
  #block_coffrets-pack .block__title {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 770px) {
  #block_coffrets-pack .compos-item {
    width: calc(50% - 10px) !important;
  }

  #block_coffrets-pack .container-fluid-histoire .container-histoire h1 {
    font-size: 22px !important;
  }
}
