#listecofret .conatiner-coffrets {
  margin-top: -9px;
  margin-right: 10px;
  width: 100%;
}

#listecofret .coffrets-item {
  border: 1px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin: 5px;
  border-radius: 3%;
  width: calc(33% - 10px) !important;
}

#listecofret .titlecoffrets {
  text-align: center;
  font-family: "prata", serif;
  font-size: 18px;
}

#listecofret .logodetail {
  width: 200px;
  text-align: center;
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.image-cof {
  border: 1px solid rgb(0, 255, 166);
}

.title-coffrets {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: "prata", serif;
  font-size: 30px;
}

#listecofret .lire-plus-coffrets {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 17px;
}
#listecofret .lire-plus-coffrets:hover {
  color: #cf9a52 !important;
}

#listecofret .card-image {
  position: relative;
  overflow: hidden;
}

#listecofret .card-image img {
  border-radius: 3%;
  transition: transform 0.7s ease;
}

#listecofret .card-image:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  #listecofret .coffrets-item {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 576px) {
  #listecofret .coffrets-item {
    width: calc(100% - 10px);
  }
}

@media screen and (min-width: 581px) and (max-width: 1176px) {
  #listecofret .titlecoffrets {
    font-size: 13px;
  }
  #listecofret .coffretsItems {
    font-size: 28px;
  }
  #listecofret .lire-plus-coffrets {
    font-size: 15px;
  }
}

@media screen and (min-width: 581px) and (max-width: 1487px) {
  .title-coffrets {
    font-size: 26px;
  }
}

@media screen and (min-width: 581px) and (max-width: 1032px) {
  .title-coffrets {
    font-size: 24px;
  }
}

@media screen and (max-width: 581px) {
  #listecofret .coffrets-item {
    border: 1px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 3%;
    margin: 1px !important;
    width: calc(70% - 10px) !important;
  }

  #listecofret .lire-plus-coffrets {
    font-size: 15px;
  }

  .row-coffrets {
    justify-content: center !important;
  }
}
