body {
  background-color: #ffffff;
}

.myCarousel-detail-product .carousel-inner img {
  width: 80% !important;
}

.myCarousel-detail-product .carousel-item img {
  border-radius: 5%;
  width: 480px !important;
  height: 500px !important;
}

.myCarousel-detail-product .carousel-indicators {
  position: relative;
  margin-top: 0px !important;
  
}


.myCarousel-detail-product .carousel-indicators > li {
  padding-top: 0px !important;
  width: 80px;
  height: -50px;
  padding-bottom: 120px;
}

.myCarousel-detail-product .carousel-indicators li img {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 80px;
  width: 80px;
  z-index: 1;
}

.myCarousel-detail-product .carousel-indicators [data-bs-target] {
  opacity: 1 !important;
}

.myCarousel-detail-product .carousel-indicators li {
  cursor: pointer;
}

.container-detail .title {
  font-family: "HelloParisSerif-Regular";
  color: #cf9a52;
  font-size: 40pt;
}

.container-detail .content-detail h2 {
  font-family: 'prata';
   font-size: 35px
}

.container-detail .content-detail .para-1 {
  width: 100% ;
  font-family: 'montage';
  font-size: 15px;
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.container-detail .content-detail .para-2 {
  padding-right: 5px;
  font-family: 'Montserrat'; 
  font-weight: 500; 
  font-size: 15px;
  text-align :justify !important;
}

.btn-group label {
  margin-right: 20px;
  border-radius: 5px;
}
.container-checkbox {
  margin-left: 25px;
}
.container-checkbox .btn-checkbox {
  background-color: #f3f3f3;
  border-radius: 5px;
  width: 100%;
  border: none;
  color: black;
  font-weight: bolder;
}

.container-detail .container-checkbox .btn-check:checked +  .btn-checkbox {
  background-color: #cf9a52 ;
  box-shadow: 0 4px 8px 0 rgba(190, 51, 27, 0.3),
    0 6px 20px 0 rgba(190, 51, 27, 0.2);

  border: none;
  color: white ;
  font-weight: bolder;
}

.btn-minus {
  margin-right: 0px;
  border: none;
  border: none;
  width: 50px;
  color: black;
  font-weight: bolder;
}

.btn-plus {
  margin-right: 10px;
  border: none;
  border: none;
  width: 50px;
  color: black;
  font-weight: bolder;
}

input {
  margin-left: 5px;
  text-align: center;
  border: 1px solid #6c757d;
}

.input-box input[type="number"] {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.input-box input[type="number"]::-webkit-inner-spin-button,
.input-box input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-box .add-to-cart {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  border: none;
  height: 50px;
  border-radius: 5px;
  background-color: #cf9a52;
  font-weight: 600;
  color: white;
}

@media screen and (max-width: 393px) {
  .myCarousel-detail-product .carousel-item img {
    width: 250px !important;
    height: 250px !important;
    margin-left: 10px;
  }


  .btn-add .add-to-cart {
    font-size: 10.5px !important;
  }

 .btn-add {
    width: 120px !important;
  }
}
@media screen and (max-width: 600px) {
  .myCarousel-detail-product .carousel-item img {
    width: 300px !important;
    height: 300px !important;
  }

  .container-detail {
    margin-left: 10px;
    margin-right: 10px;
  }
  .container-detail .img-logo {
    width: 150px !important;
  }
  .container-detail .para-2 {
    margin-top: 10px !important;
    text-align: left !important;
    padding-left: 20px !important;
  }
  .container-detail h1 {
    margin: auto;
    font-size: 30px !important;
  }

  .container-detail  h2 {
    margin-left: 2px;
    font-size: 25px !important;
  }

  .container-detail .price-h1 {
    margin-left: 0px !important;
    
  }


  .btn-add .add-to-cart {
    font-size: 13px;
    width: 150px !important;
  }

  .btn-add {

    width: 150px;
    margin-left: 30pt;
  }
}

@media screen and (min-width: 600px) and (max-width: 766px) {
  .btn-group label {
    margin-right: 20px;
    border-radius: 5px;
  }
  .myCarousel-detail-product .carousel-item img {
    width: 400px !important;
    height: 400px !important;
    margin-left: 10px;
  }

  .container-detail p {
    margin-right: 10px;
  }
  .container-detail h1 {
    font-size: 30px !important;
  }

  .container-detail h2 {
    font-size: 25px !important;
  }


  .btn-add{
    margin-left: 60px;
    width: 160px !important;
  }

  .btn-add a {
    
    width: 150px !important;
  }
}

@media screen and (min-width: 766px) and (max-width: 1000px) {
  .myCarousel-detail-product .carousel-item img {
    width: 450px !important;
    height: 300px !important;
    margin-left: 10px;
  }

  .container-detail p {
    margin-left: 10px;
    margin-right: 10px;
  }
  .container-detail h1 {
    margin-left: 2px;
    font-size: 30px !important;
  }

  .container-detail h2 {
    margin-left: 2px;
    font-size: 25px !important;
  }


  .btn-add {
    margin-left: 60px;
    width: auto !important;
   
  }

}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .btn-add {
    margin-left: 40px;
    width: auto !important;
  }
}

