.container-fluid-boutique {
  top: 0px !important;
  bottom: 0px !important;
  /*border-top: 1px #c49c63 solid;*/
  /*border-bottom: 1px #c49c63 solid;*/
  background: rgb(254, 254, 254);
  background: linear-gradient(
    115deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(230, 230, 230, 1) 99%
  );
}

@media screen and (max-width: 900px) {
  .container-fluid-boutique .bg-history-img {
    background-size: contain;
    height: 200px;
  }
}
/* .container-fluid-histoire .container-boutique {
    
     background-color: #f9f9f7; 
  } */

.container-fluid-boutique .container-boutique h1 {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: "prata", serif;
  font-size: 30px;
}

.container-fluid-boutique .container-boutique .boutique-img-logo {
  width: 200px !important;
}

.container-fluid-boutique .container-boutique h3 {
  width: 85%;
  color: #343535;
  font-size: 25px;
  font-family: "prata", sans-serif;
  text-align: center;
  margin: 20px 0 0 0;
  /*margin-top: 50px;*/
  /*margin-left: 0px;*/
  /*margin-right: 0px;*/
}

.container-fluid-boutique .container-boutique p {
  width: 90%;
  color: #343535;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.img-zome img {
  transition: transform 0.9s ease;
}

.img-zome:hover img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 480px) {
  .container-fluid-boutique .container-boutique h1 {
    font-size: 25px;
  }

  .container-fluid-boutique .container-boutique h3 {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
    font-size: 15px;
  }

  .container-fluid-boutique .container-boutique p {
    font-size: 12px;
  }

  .container-fluid-boutique .container-boutique .boutique-img-logo {
    width: 200px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .container-fluid-boutique .container-boutique .boutique-img-logo {
    width: 300px !important;
  }

  .container-fluid-boutique .container-boutique h1 {
    font-size: 30px;
  }

  .container-fluid-boutique .container-boutique h3 {
    font-size: 18px;
  }

  .container-fluid-boutique .container-boutique p {
    font-size: 15px;
  }
}
