#offcanvas-panier .btn-close {
  transform: rotate(0deg);
  animation: rotate-reset 0.3s ease-in-out forwards;
}

#offcanvas-panier .btn-close:hover {
  transform: rotate(90deg) ;
  animation: rotate-hover 0.3s ease-in-out forwards;
}
 
.quantity-panier-container .input-panier-quantity{
  border: 1px solid #cf9a52;
  color: #5c5a58;
  text-align: center;
  height: 25px;
  width: 50px;
  border-radius: 5px;
  background-color: white;
  margin: 0px 5px;
  padding: 0px;
  font-size: 15px;
  outline: none;

}

.quantity-panier-container .button-panier-quantity{
  border: 1px solid #cf9a52;
  color: #5c5a58;
  text-align: center;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: white;
  margin: 0px 5px;
  padding: 0px;
  font-size: 15px;
}

.quantity-panier-container .button-panier-quantity:focus{
  box-shadow: none !important;
  
}
@keyframes rotate-reset {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotate-hover {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

#offcanvas-panier .btn-remove-item {
    color: #cf9a52;
    border: 1px solid #cf9a52 !important;
    animation: border-pulse 0.5s ease-in-out alternate infinite; 
  }
  
  @keyframes border-pulse {
    from { border-color: #cf9a52; }
    to { border-color: #e8d0a3; } 
  }
  
  #offcanvas-panier .btn-remove-item:hover {
    background: none !important;
    color: #3d3b39;
  }