

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.section-products {
  padding: 80px 0 54px;
}

.section-products .box-header .header h3 {
  text-align: left;
  font-family: "montserrat", sans-serif;
  font-size: 1rem;
  color: #cf9a52;
  font-weight: 500;
}

.section-products .box-header .header h2 {
  font-size: 2.2rem;
  font-family: "montserrat", sans-serif;
  font-weight: 500;
  color: #444444;
  text-align: left;
}
.section-products .div-link a {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #444444;
}

.section-products .single-product {
  margin-bottom: 26px;
}

.section-products .single-product .part-1 {
  position: relative;
  height: 200px;
  width: auto;
  max-height: 290px;
  margin-bottom: 20px;
  overflow: hidden;
}

.section-products .single-product .part-1 {
  text-align: center;

}

.section-products .single-product .part-1 img {
  transition: all 0.3s;
}

.section-products .single-product:hover .part-1 img {
  transform: scale(1.2, 1.2) rotate(2deg);
}
/* .section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
  position: absolute;
  top: 15px;
  left: 20px;
  color: #ffffff;
  background-color: #fe302f;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 0.85rem;
} */

.section-products .single-product .part-1 ul {
  position: absolute;
  left: 72px;
  bottom: -41px;
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
  bottom: 30px;
  opacity: 1;
}

.section-products .single-product .part-1 ul li {
  display: inline-block;
  margin-right: 4px;
}

.section-products .single-product .part-1 ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  color: #444444;
  text-align: center;
  box-shadow: 0 2px 20px rgb(50 50 50 / 10%);
  transition: color 0.2s;
}

.section-products .single-product .part-1 ul li a:hover {
  color: #cf9a52;
}

.section-products .single-product .part-2 .input-box input {
  border: 1px solid #cf9a52;
  width: 50px;
  height: 50px;
  color: #272726;
  height: 50px;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
}

.section-products .single-product .part-2 .input-box input:focus {
    border: 1px solid #cf9a52 !important;
}
.section-products .single-product .part-2 .input-box button {
  box-shadow: none;
  border-radius: 10px;
}
.section-products .single-product .part-2 .input-box button.button-clicked {
  border: 1px solid #d89211 !important;
}
.section-products .single-product .part-2 .product-title {
  font-family: "prata", serif;
  font-size: 18px;
}

.section-products .single-product .part-2 h4 {
  display: inline-block;
  font-size: 1rem;
}

.section-products #list-product .pagination-list .pagination-link {
  width: 60px;
  margin-bottom: 50px;
  text-align: center;
  cursor: pointer;
}

.section-products #list-product .pagination-list .page-item.active .pagination-link {
  background-color: #cf9a52;
  border-color: #cf9a52;
  color: white;
}

.section-products .btn_to_cart {
  background-color: #c49c63;
  border: 1px solid #c49c63;
  width: 45%;
  padding: 15px;
  border-radius: 7px;
  color: #fff;
  font-size: 15px;
  font-family: "montserrat", sans-serif;
  text-transform: capitalize;
  text-align: center;
  
}

.section-products .btn_to_cart:hover {
  background-color: #fff;
  color: #c49c63;
  border: 1px solid #c49c63;
}

