.container-fluid-cookies {
  top: 0px !important;
  bottom: 0px !important;
  margin: auto;
  background: #fff;
  /* background: linear-gradient(
    115deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(230, 230, 230, 1) 99%
  ); */
}

.container-fluid-cookies .container-cookies h1 {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: "prata", serif;
  font-size: 20px;
}

.container-fluid-cookies .container-cookies p{
  width: 90%;
  color: #343535;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.container-fluid-cookies .container-cookies p span {
  color: #c49c63;
  font-weight: bold;
}

.container-fluid-cookies .container-cookies ol li {
  color: #000000;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  margin-left: 60px;
}

.container-fluid-cookies .container-cookies ul li {
    width: 90%;
  color: #000000;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 60px;
}

/* For 480 Resolution */
@media screen and (max-width: 700px) {
    .container-fluid-cookies .container-cookies p{
      font-size: 15px;
  
    }

    .container-fluid-cookies .container-cookies h1 {
      font-size: 17px;
    }

    .container-fluid-cookies .container-cookies ol li {
      font-size: 15px;
      margin-left: 0px;
      width: 93%;
    }

    .container-fluid-cookies .container-cookies ul li {
        font-size: 15px;
        margin-left: 0px;
        width: 93%;
      }
    
}

