
#cgv {
  width: 95% !important;
}

#cgv p {
  font-size: 18px;
  font-family: "montserrat", sans-serif;
}

#cgv strong {
  font-family: "montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700 !important;
  color: #cf9a52;
}

#cgv .lien-cgv {
  color: #cf9a52 !important;
  text-decoration: none !important;
}

#cgv .lien-cgv:hover {
  color: black !important;
  text-decoration: none !important;
}
