.card-container-1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card-description {
  width: 300px;
  height: 600px;
  margin-right: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 14px 18px rgba(0, 0, 0, 0.1), 4px 0 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.card-description:hover {
  transform: translateY(-5px);
}

.card-description img {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.card-info {
  padding: 20px 0;
  font-size: 15px;
  margin-bottom: 10px;
  font-family: "Montserrat-Regular";
}

.card-info .style-h2 {
  margin-left: 0px !important;
  font-family: "Prata-Regular";
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  margin: 3;
}

.card-info .Medium {
  margin-left: 0px !important;
  color: #9f9f9f;
  margin-bottom: 10px;
  font-size: 14px;
}

.card-info .style-p {
  margin-left: 0px !important;
}

.lien {
  text-decoration: none;
  color: black !important;
  font-size: 14px !important;
  transition: color 0.3s ease;
  cursor: pointer;
  padding: 15px;
  display: block;
  text-align: center;
  margin-top: 50px;
}

.style {
  font-family: "Prata-Regular";
}
