#container-valus {
    
  top: 0px !important;
  bottom: 0px !important;
  background: url("../images/NOS-VALEURS.png") no-repeat bottom left fixed #f7f7f5;
}


#container-valus .container-valus-content h1 {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: 'prata', serif;
  font-size: 30px;
}

#container-valus .container-valus-content .valus-img-logo {
  width: 200px !important;  
}

#container-valus .container-valus-content h3 {
  color: #343535;
  font-size: 25px;
  font-family: "prata", sans-serif;
  text-align: center;
}

#container-valus .container-valus-content .para-value {
  width: 85%;
  color: #343535;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

#container-valus .container-valus-content p span {
  color: #c49c63;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  #container-valus .container-valus-content h1 {
    font-size: 25px ;
  }

  #container-valus .container-valus-content h3 {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
    font-size: 15px;
  }

  #container-valus .container-valus-content p {
    font-size: 12px;
  }

  #container-valus .container-valus-content .valus-img-logo {
    width: 200px !important;  
  }
  
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  #container-valus .container-valus-content .engagement-img-logo {
    width: 300px !important;
  }

  #container-valus .container-valus-content h1 {
    font-size: 30px;

  }

  #container-valus .container-valus-content h3 {
    font-size: 18px;
  }

  #container-valus .container-valus-content p {
    font-size: 15px;
  }
}

