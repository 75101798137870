@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap");

.footer2 {
  background-image: url(./../../../assets/images/image-background-rectangle.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* filter: grayscale(100%); */
  color: #fff;
  padding: 100px 0;
}

.container-rectangle {
  padding-left: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  margin-left: 10px !important;
  margin-bottom: 0px !important;
  padding-left: 0px !important;
}

.footer-text h2 {
  font-size: 25px;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  color: white;
  font-family: "prata", serif;
}

.subscribe-form .input-email {
  background-color: transparent;
  color: white;
  border: 1px solid black !important;
  border-radius: 10px 0 0 10px;
  padding-top: 10px !important;
  padding-bottom: 9px !important;
  width: 300px !important;
  font-size: 17px;
  font-family: "Montserrat";
}
.subscribe-form {
  padding-left: 200px;
}
.subscribe-form button {
  background-color: #cf9a52;
  border: 1px solid black !important;
  color: #fff;
  padding: 10px 20px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  width: 200px;
  font-size: 17px;
  font-weight: bold;
}

.subscribe-form button:hover {
  background-color: #cf9a52;
}
.subscribe-form .input-email::placeholder {
  color: white;
  opacity: 1;
  font-size: 17px;
  font-family: "Montserrat";
}

.subscribe-form .input-email::-webkit-input-placeholder {
  -webkit-transition: 0.5s;
}
.subscribe-form .input-email:focus::-webkit-input-placeholder {
  color: transparent;
}
.subscribe-form .input-email:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subscribe-form {
    margin-top: 50px !important;
    padding-left: 0px;
  }

  .subscribe-form .input-email {
    width: 180px !important;
    margin-left: 0px !important;
  }

  .subscribe-form button {
    width: 100px;
  }

  .footer-text h2 {
    font-size: 20px;
  }

  .subscribe-form .input-email::placeholder {
    font-size: 15px;
  }
}

@media screen and (min-width: 600px) and (max-width: 766px) {
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subscribe-form {
    margin-top: 30px !important;
    padding-left: 0px !important;
  }

  .subscribe-form .input-email {
    margin-left: 0px !important;
    width: 200px;
  }

  .subscribe-form button {
    width: 150px;
  }

  .footer-text h2 {
    font-size: 20px;
  }

  .subscribe-form .input-email::placeholder {
    font-size: 15px;
  }
}

@media screen and (min-width: 766px) and (max-width: 991px) {
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subscribe-form {
    margin-top: 50px !important;
    padding-left: 0px;
  }

  .subscribe-form .input-email {
    width: 180px;
  }

  .subscribe-form button {
    width: 150px;
  }

  .footer-text h2 {
    margin-right: 20px;
    font-size: 20px;
  }

  .subscribe-form .input-email::placeholder {
    font-size: 15px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .subscribe-form {
    padding-left: 0px;
  }

  .subscribe-form .input-email {
    width: 180px;
  }

  .subscribe-form button {
    width: 150px;
  }

  .footer-text h2 {
    margin-right: 20px;
    font-size: 20px;
  }

  .subscribe-form .input-email::placeholder {
    font-size: 15px;
  }
}

@media screen and (max-width: 599px) {
  .subscribe-form button {
    width: 110px;
  }
}
