@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap');

.container-home {
    background-color: #faf3eaed
}
.container-home .text-p{
    font-family: 'Montserrat', sans-serif;
    line-height: 30px;
    font-size: 19px;
    text-align: justify;
    font-weight: bold;
    color: #3c3b3a;

}
.container-home .text-h1{
    margin-top: 55px;
    font-family: 'prata'; 
    margin-top: 55px;
    text-align: center;
    padding-top: 0%;
    color: #3c3b3a;
    font-family: 'Prata', serif;
    font-size: 30px;
}

.container-home .Image-2 {
    width: 500px
}

@media screen and (max-width: 300px) {
    
    .container-home .text-h1{
        width: 100%;  
        padding-top: 0%;
        font-size: 20px;
    }
    .container-home .text-p{
        padding-top: 10px !important;
        text-align: center;
        font-size: 8px;
        margin: 0%;
    } 
}

@media screen and (min-width: 400px) {
    .container-home .text-p{
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        color: #3c3b3a;
    
    }
    .container-home .Image-2{
        width: 400px !important;
    }
}