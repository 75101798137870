@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap');

.carousel-indicators [data-bs-target] {
    border-radius: 100% !important;
    width: 10px !important;
    height: 10px !important;
}
.carousel-caption-1{
    top: 80px;   
}
.ima-box-slider{
    height: 80vh !important;
    width: 100%;
    
}
.carousel-caption-1 h3{
    font-family: 'Prata', serif;
    color: #2C2C2C;
    font-size: 32.2px;
    margin-left:100px;
    margin-right:100px;
}

.carousel-control-next-icon {
    z-index: 1;
    width: 50px !important;
    height: 50px !important;
    border-radius: 100%;
    background-image: none !important;
    border: 1px solid #c49c63 !important;
    transition: background-color 0.3s ease;
}
.carousel-control-next-icon :hover{
    border-radius: 100% !important;
    cursor: pointer !important;

}

.carousel-control-prev-icon {
    z-index: 1;
    width: 50px !important;
    height: 50px !important;
    border-radius: 100%;
    background-image: none !important;
    border: 1px solid #c49c63 !important;
    transition: background-color 0.3s ease;
}

.carousel-control-next-icon:before {
    content: "";
    display: inline-block;
    margin-top: 7px !important;
    width: 30px;
    height: 30px;
    background-image: url(./../../../icons/arrow-12.png);
    filter:grayscale(40%);
    background-size: cover;
}

.carousel-control-prev-icon::before {
    content: "";
    display: inline-block;
    margin-top: 7px !important;
    width: 30px;
    height: 30px;
    background-image: url(./../../../icons/arrow-11.png);
    filter:grayscale(40%);
    background-size: cover;
    
}



.carousel-control-prev-icon:hover{
    border-radius: 100% !important;
    cursor: pointer !important;
}
.carousel-caption button{
    font-family: 'Roboto', sans-serif;
    background-color: #2C2C2C;
    color: white;
    font-size: 14px;
    border:none;
    width: 150px;
    border-radius:0%;
    margin-bottom: 20px;
}


@media only screen and (max-width: 425px) {
    .carousel-caption-1 h3{
        margin-top: -80px;
        font-size: 13px;
        margin-left:0px;
        margin-right:0px;
    }

    .ima-box-slider{
        height: auto !important;
        width: 100%;
        
    }
    .carousel-caption button{
        font-size: 8px;
        border:none;
        width: 50px;
        margin-bottom: 2px;
    }

    .carousel-control-next-icon {
        width: 20px !important;
        height: 20px !important;
    }

    .carousel-control-prev-icon {
        width: 20px !important;
        height: 20px !important;
    }
  }


  @media only screen and (min-width: 425px) and (max-width: 600px) {
    .carousel-caption-1 h3{
        margin-top: -60px;
        font-size: 16px;
        margin-left:0px;
        margin-right:0px;
    }

    .ima-box-slider{
        height: auto !important;
        width: 100%;
        
    }
    .carousel-caption button{
        font-size: 8px;
        border:none;
        width: 50px;
        margin-bottom: 10px;
    }

    .carousel-control-next-icon {
        width: 22px !important;
        height: 22px !important;
    }

    .carousel-control-prev-icon {
        width: 22px !important;
        height: 22px !important;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 800px)  {
    .carousel-caption-1 h3{
        margin-top: -60px;
        font-size: 16px;
        margin-left:0px;
        margin-right:0px;
    }

    .ima-box-slider{
        height: auto !important;
        width: 100%;
        
    }
    .carousel-caption button{
        font-size: 8px;
        border:none;
        width: 50px;
        margin-bottom: 10px;
    }

    .carousel-control-next-icon {
        width: 22px !important;
        height: 22px !important;
    }

    .carousel-control-prev-icon {
        width: 22px !important;
        height: 22px !important;
    }
  }

  @media only screen and (min-width: 800px)and (max-width: 1120px)  {
    .carousel-caption-1 h3{
        margin-top: -50px;
        font-size: 25px;
        margin-left:0px;
        margin-right:0px;
    }

    .ima-box-slider{
        height: auto !important;
        width: 100%;
        
    }
    .carousel-caption button{
        font-size: 12px;
        border:none;
        width: 90px;
        margin-bottom: 10px;
    }

    .carousel-control-next-icon {
        width: 40px !important;
        height: 40px !important;
    }

    .carousel-control-prev-icon {
        width: 40px !important;
        height: 40px !important;
    }
  }


