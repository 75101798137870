.container-fluid-liste .nav-link-prata {
  font-family: "Prata-Regular";
  color: grey;
}
.container-fluid-liste .nav-link-prata:hover{
    color: black;
}
.container-fluid-liste .Mons {
  font-family: "Montserrat-Medium";
  font-size: 18px;
}

@media screen and (max-width: 360px) {

  .container-fluid-liste .nav-link-prata { 
    font-size: 9.7px !important;
    margin-bottom: 10px  !important;
  }
  
  .container-fluid-liste .Mons {
    font-family: "Montserrat-Medium";
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {

  .container-fluid-liste .nav-link-prata { 
    font-size: 11px ;
    margin-bottom: 10px  !important;
  }
  
  .container-fluid-liste .Mons {
    font-family: "Montserrat-Medium";
    font-size: 15px;
  }
}

@media screen and (min-width: 600px) and (max-width: 766px) {

  .container-fluid-liste .nav-link-prata {
    font-size: 20px ;
    margin-bottom: 10px  !important;
  }
  
  .container-fluid-liste .Mons {
    font-family: "Montserrat-Medium";
    font-size: 15px;
  }
}

@media screen and (min-width: 766px) and (max-width: 1000px) {

  .container-fluid-liste .nav-link-prata {
    font-size: 20px ;
    margin-bottom: 10px  !important;
  }
  
  .container-fluid-liste .Mons {
    font-family: "Montserrat-Medium";
    font-size: 15px;
  }

  
  
}