@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&family=Source+Code+Pro:wght@200;300;400;500;600;700&display=swap");

.box-cards h5 {
  font-family: "prata", serif;
  font-size: 16px;
  font-weight: 900;
}

.box-cards .card-text-slider {
  margin-left: 0px !important;
  font-family: "SourceSansPro-Regular";
  font-size: 15px;
  font-weight: 500;
}

.box-cards a {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #595667;
}
#slider-container-cards .card-image {
  border: none;
  border-radius: 4%;
  transition: transform 0.3s ease-out;
}

#slider-container-cards .card-image:hover {
  transform: scale(1.05);
}

#slider-container-cards .card-image .background-image {
  background-color: #f3f3f3;
  width: 320px;
  border-radius: 5%;
}


.slick-next {
  right: -40px !important;
  z-index: 1;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  border: 1px solid #c49c63 !important;
  transition: background-color 0.3s ease;
}

.slick-prev {
  left: -40px !important;
  z-index: 1;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  border: 1px solid #c49c63 !important;
  transition: background-color 0.3s ease;
}

.slick-dots {
  display: none !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#slider-container-cards h3 {
  margin-top: 70px;
}

#slider-container-cards .slick-prev:before,
#slider-container-cards .slick-next:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 30px;
  background-image: url(./../../../icons/arrow-12.png);
  filter: grayscale(40%);
  background-size: cover;
}

.slick-prev:before {
  transform: rotateY(180deg);
}

@media only screen and (max-width: 400px) {
  .box-cards h5 {
    font-size: 16px;
  }
  .box-cards p {
    font-size: 12px;
  }
  .box-cards a {
    font-size: 12px;
  }

  .slick-next {
    right: -5px !important;
    width: 35px !important;
    height: 35px !important;
  }

  .slick-prev {
    left: -5px !important;
    width: 35px !important;
    height: 35px !important;
  }

  #slider-container-cards .slick-prev:before,
  #slider-container-cards .slick-next:before {
    width: 17px;
    height: 29px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .slick-next {
    right: -5px !important;
    width: 35px !important;
    height: 35px !important;
  }

  .slick-prev {
    left: -5px !important;
    width: 35px !important;
    height: 35px !important;
  }

  #slider-container-cards .slick-prev:before,
  #slider-container-cards .slick-next:before {
    width: 20px;
    height: 29px;
  }
}
