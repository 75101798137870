.cookie-consent-container {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: #ffffff;
  border-color: #f4f4f4;
}

.cookie-consent {
  font-family: "montserrat", sans-serif;
  padding: 20px;
  color: rgb(0, 0, 0);
}

.cookie-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
}


.cookie-icon:hover {
  transform: scale(1.2);
}

.cookie-consent-container .cky-title {
  color: #212121;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px 0;
}

.cookie-consent-container .btnaccpte {
  color: #ffffff;
  background-color: #cc9c57;
  border: 2px solid #cc9c57;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cookie-consent-container .btnaccpte:hover {
  background-color: #ffae57;
}

.cookie-consent-container .btnrefuser {
  color: #cc9c57;
  background-color: transparent;
  border: 2px solid #cc9c57;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

@media screen and (max-width: 574px) {
  .cookie-consent-container .cky-title {
    font-size: 16px;
  }

  .cookie-consent-container .btnrefuser {
    font-size: 14px;
  }

  .cookie-consent-container .btnaccpte {
    font-size: 14px;
  }

  .des-coki {
    font-size: 14px;
  }
}
