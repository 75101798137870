.category-image {
  border: 1px solid rgb(253, 253, 253);
  border-radius: 4%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 100px;
  padding: 0px;
  object-fit: cover;
}

.name-category {
  text-align: justify !important;
  font-family: "prata", serif;
  padding: 1.2rem;
  font-size: 14px;
  margin: 5px;
}

.category-image:hover {
  transform: scale(1.1);
  transition: 0.8s;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (min-width: 200px) and (max-width: 1100px) {
  .categoty-product {
    width: auto !important;
    margin: 10px;
  }

  .name-category {
    font-family: "prata", serif;
    padding: 1.2rem;
    font-size: 10px;
  }

  .category-image {
    border: 1px solid rgb(253, 253, 253);
    border-radius: 4%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 80px;
    padding: 0px;
    object-fit: cover;
  }
}

/* @media screen and (max-width: 991px) {
  .col-test {
    display: grid;
    border: 1px solid blue !important;
  }
} */
