footer {
  background: #f1f1ef;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-logo img {
  /*width: 600px;*/
  margin-right: 80px;
}

.address-item {
  display: flex;
  align-items: center;
}

.itmes-list li a {
  font-family: "roboto-Regular";
  font-weight: 200 !important;
  font-size: 20px;
}

.itmes-list li {
  text-align: left;
}
.itmes-list li a {
  left: 50px;
  width: 100%;
  color: #696968;
}

@media screen and (max-width: 600px) {
  .disable-width-auto .itmes-list {
    margin-left: -10px !important;
  }
}

@media screen and (max-width: 766px) {
  .itmes-list li {
    text-align: center;
  }
  .disable-width-auto .itmes-list {
    margin-left: 90px;
  }
  .itmes-list li a {
    left: 50px;
    width: 100%;
    color: #696968;
  }
  .footer-logo img {
    margin: auto;
  }
}
