@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600&family=Poppins:wght@200;500;600&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700;1,900&family=Source+Code+Pro:wght@200;300;400;500;600;700&display=swap");

.slider-container h3 {
  margin-top: 70px;
  font-family: "Prata", serif;
  color: #000;
  font-size: 36px;
}
.title-box {
  background-color: #f7f6f5;
}
h6 {
  color: #000;
  font-family: "Prata", serif;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 0px;
  margin: 100px 0 80px;
}

.title-box .text-box {
  width: 97%;
  margin-left: 0px !important;
  font-family: "Montserrat", sans-serif;
  color: #6d6a79;
  font-size: 17px;
  text-align: center;
}

.container-fluid .slick-slider .slick-next {
  	right: 0 !important;
  	background:none !important;
  	box-shadow: none !important;
	border: none !important;
	border: 1px solid #c49c63 !important;
  transition: background-color 0.3s ease;
}

.container-fluid .slick-slider .slick-prev {
	left: 0 !important;
	background:none !important;
	box-shadow: none !important;
	border: none !important;
	border: 1px solid #c49c63 !important;
  transition: background-color 0.3s ease;
  }
  .container-fluid .slick-slider .slick-next {
	  right: -40px !important;
  }
  .container-fluid .slick-slider .slick-prev {
	  left: -40px !important;
  }
.container-fluid .slick-slider .slick-next:before,
.container-fluid .slick-slider .slick-prev:before {
    content: "";
  display: inline-block;
  width: 25px;
  height: 30px;
  background-image: url(./../../../icons/arrow-12.png);
  filter:grayscale(40%);
  background-size: cover;
}

@media screen and (max-width: 574px) {
  .title-box {
    height: 190px !important;
  }
  .title-box h6 {
    text-align: center !important;
    font-size: 17px !important;
    margin-top: -30px;
    padding-top: 0px;
  }

  .title-box p {
    font-size: 10px;
  }

  .card-content-box img {
    width: 100% !important;
    height: 200px !important;
  }
  .container-fluid .slick-slider .slick-next {
	  right: -15px !important;
  }
  .container-fluid .slick-slider .slick-prev {
	  left: -15px !important;
  }
}
@media screen and (min-width: 990px)  {
  .title-box {
    height: 272px !important;
  }
}

@media screen and (max-width: 767px) {
  .col-box {
    margin-left: -100px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 2040px){
	.image-slider{
		height: 273px !important;	
	}
}

@media screen and (min-width: 2040px) and (max-width: 2100px){
	.image-slider{
		height: 253px !important;	
	}
}

@media screen and (min-width: 2100px) and (max-width: 2196px){
	.image-slider{
		height: 250px !important;	
	}
}

@media screen and (min-width: 2196px) and (max-width: 2600px){
	.image-slider{
		height: 220px !important;	
	}
}


