#engagement-page {
  top: 0px !important;
  bottom: 0px !important;
  /* border-top: 1px #c49c63 solid; */
  /* border-bottom: 1px #c49c63 solid; */
}

#engagement-page .container-engagement-page {
  background-image: url(./../images/engagement-page-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  /*margin-top: 30px;*/
}

#engagement-page .container-engagement-page h1 {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: "prata", serif;
  font-size: 30px;
}

#engagement-page .container-engagement-page .engagement-img-logo {
  width: 200px !important;
}

#engagement-page .container-engagement-page h2 {
  color: #343535;
  font-size: 25px;
  font-family: "prata", sans-serif;
  text-align: center;
}

#engagement-page .container-engagement-page p {
  color: #343535;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px;
  margin-bottom: 50px;
}

#engagement-page .container-engagement-page p span {
  color: #c49c63;
  font-weight: bold;
}

#engagement-page .container-engagement-page .row-display {
  display: none;
}

@media screen and (max-width: 480px) {
  #engagement-page .container-engagement-page {
    background: none !important;
  }
  #engagement-page .container-engagement-page h1 {
    font-size: 25px;
  }

  #engagement-page .container-engagement-page .col-display {
    display: none;
  }

  #engagement-page .container-engagement-page .row-display {
    display: block;
  }

  #engagement-page .container-engagement-page h2 {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
    font-size: 15px;
  }

  #engagement-page .container-engagement-page p {
    font-size: 12px;
  }

  #engagement-page .container-engagement-page .engagement-img-logo {
    width: 200px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 1420px) {
  #engagement-page .container-engagement-page {
    background: #f6f5f5 !important;
  }

  #engagement-page .container-engagement-page .col-height-display {
    display: none;
  }

  #engagement-page .container-engagement-page .col-paragraph {
    width: 100%;
  }

  #engagement-page .container-engagement-page .col-display {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  #engagement-page .container-engagement-page .row-display {
    display: block;
  }

  #engagement-page .container-engagement-page .row-display img {
    width: 100% !important;
  }

  #engagement-page .container-engagement-page .engagement-img-logo {
    width: 300px !important;
  }

  #engagement-page .container-engagement-page h1 {
    font-size: 30px;
  }

  #engagement-page .container-engagement-page h2 {
    font-size: 18px;
  }

  #engagement-page .container-engagement-page p {
    font-size: 15px;
  }
}

/* @media screen and (min-width: 851px) and (max-width: 1200px) {
    #engagement-page .container-engagement-page .col-display{
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  } */
