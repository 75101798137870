header {
  padding: 10px 0;
  background: #f1f1ef;
}
header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.langues {
  float: left;
  margin-left: 10px;
}
header ul li {
  float: left;
  padding: 0 6.5px;
  cursor: pointer;
}

header ul li img {
  height: 25px;
}

.icons {
  float: right;
  margin-right: 10px;
}
.clear {
  clear: both;
}
/*.marque {
   padding: 10px 0; 
}
*/

.form-search {
  margin-right: -35px !important;
}
.size-li li {
  font-size: 16px !important;
}

.logo {
  width: 20%;
  float: left;
  text-align: center;
}
.logo img {
  height: 140px;
}
.rightLines,
.leftLines {
  width: 40%;
  float: left;
  margin-top: 70px;
}
.line {
  width: 100%;
  height: 2px;
  background: #cc9c57;
  margin: 5px 0;
}

.dropdown-menu {
  width: 60rem !important;
  margin-top: 24px !important;
}

.dropdown-itemscof {
  margin-top: 24px !important;
  margin-left: 105px !important;
  width: 820px !important;
}

.icon-search {
  border: none !important;
  box-shadow: none !important;
  margin: 0;
  padding: 0;
}

.input-search {
  width: 15% !important;
  position: relative;
  top: 30%;
  left: 30%;
  transform: translate(80%, -15%);
  border: 1px solid #6c757d;
  box-shadow: none !important;
}

.btn-search {
  width: 50px;
  height: 30px;
}

.mrg{
  margin-left: 15px !important;
}

.icon-pays {
  margin-right: -14px;
}

@media screen and(max-width:991px) {
  .navbar-toggler {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    transform: translate(-50%, -70%);
  }
}

@media screen and (max-width: 991px) {
  .option-drop {
    width: 28rem !important;
    margin-left: -10px;
  }

  .dropdown-items {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 25rem !important;
  }
  .dropdown-itemscof {
    width: 17rem !important;
    margin-top: 60px !important;
    margin-left: 0px !important;
  }
  .coffrets-drop {
    width: 17rem !important;
  }

  .nav-menu ul {
    flex-direction: column !important;
    float: left !important;
  }

  .input-search {
    position: relative;
    top: 80% !important;
    left: -35%;
    transform: translate(80%, -130%);
  }
  .form-search {
    margin-right: 0px !important;
  }

  .nav-header {
    /* position: relative;
    top: 80% !important;
    left: 0%;
    transform: translate(0%, -0%);
    border: 1px solid #6c757d; */
    background: #f1f1ef;
  }

  .leftLines,
  .rightLines {
    display: none;
  }

  .logo {
    width: 100%;
    float: left !important;
    text-align: center;
  }
  .logo img {
    height: 110px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1572px) {
  .size-li li {
    font-size: 14px;
  }
}

@media screen and (min-width: 574px) and (max-width: 991px) {
  .input-search {
    width: 35% !important;
    position: relative;
    top: 80% !important;
    left: -43%;
    transform: translate(80%, -80%);
  }
}

@media screen and (max-width: 573px) {
  .input-search {
    width: 20% !important;
    position: relative;
    top: 90% !important;
    left: -38%;
    transform: translate(80%, -145%);
  }
}
