.conatiner-product {
  margin-top: -9px;
  margin-right: 10px;
  width: 100%;
}

.product-item {
  border: 1px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 3%;
  margin: 5px;
  width: calc(24% - 10px) !important;
}

.title-product {
  text-align: center;
  font-family: "prata", serif;
  font-size: 17px;
}

.logodetail {
  width: 200px;
  text-align: center;
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.title-miels {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: "prata", serif;
  font-size: 30px;
}

.lire-plus-miles {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.lire-plus-miles:hover {
  color: #cf9a52 !important;
}
.card-image {
  position: relative;
  overflow: hidden;
}

.conatiner-liste-miels .card-image img {
  transition: transform 0.7s ease;
}

.conatiner-liste-miels .card-image:hover img {
  transform: scale(1.1);
}

.pagination-list .pagination-link {
  width: 60px;
  margin-bottom: 50px;
  text-align: center;
  cursor: pointer;
}

.pagination-list .page-item.active .pagination-link {
  background-color: #cf9a52;
  border-color: #cf9a52;
  color: white;
}

@media screen and (max-width: 768px) {
  .product-item {
    width: calc(50% - 10px);
  }
}



@media screen and (min-width: 581px) and (max-width: 1176px) {
  .lire-plus-miles {
    font-weight: bold;
    font-size: 15px;
  }

  .title-product {
    font-size: 13px;
  }
  .title-miels {
    font-size: 28px;
  }
}

@media screen and (min-width: 581px) and (max-width: 1487px) {
  .title-miels {
    font-size: 26px;
  }
}

@media screen and (min-width: 581px) and (max-width: 1032px) {
  .title-miels {
    font-size: 24px;
  }
}

@media screen and (max-width: 581px) {
  .product-item {
    border: 1px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 3%;
    margin: 1px !important;
    width: calc(70% - 10px) !important;
  }

  .row-mobile-miles {
    justify-content: center !important;
  }
}

@media only screen and (min-width: 590px) and (max-width: 1120px) {
  .product-item {
    border: 1px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 3%;
    margin: 1px !important;
    width: calc(30% - 10px) !important;
  }
}


@media screen and (max-width: 500px) {
  .product-item {
    width: calc(50% - 10px) !important;
    margin: 2px !important;
  }
  .title-product {
    font-size: 14px !important;
  }

  .lire-plus-miles{
    font-size: 13px !important;
  }
}
