.page-404 img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 600px;
    transform: translate(-50%, -50%);
}

.page-404 a {
    border:  1px solid #cf9a52;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: #cf9a52;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 40px;
   
}
.page-404 a:hover {
    border:  1px solid #cf9a52;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    background: #cf9a52;
    color: #fff;
    transition: background-color 1s ease , color 1s ease;
  }

  
  .page-404 img {
    animation: float 2s infinite ease-in-out alternate;
  }

  @keyframes float {
    100% {
      transform: translate(-50%, -45%);
    }
  }
  @media (max-width: 800px) {
    .page-404 img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 300px;
        transform: translate(-50%, -50%);
    }
    
    .page-404 a {
        position: absolute;
        left: 50%;
        top: 85%;
        transform: translate(-50%, -50%);
       
    }
  }