
.container-fluid-histoire {
  top: 0px !important;
  bottom: 0px !important;
  background: rgb(254,254,254);
  background: linear-gradient(115deg, rgba(254,254,254,1) 0%, rgba(230,230,230,1) 99%);

}

.container-fluid-histoire .bg-history-img{
  background-image: url(./../images/bg-history.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 40rem;
}

@media screen and (max-width: 900px) {

  .container-fluid-histoire .bg-history-img{
    background-size: contain;
    height: 200px;
  }
}

.container-fluid-histoire .container-histoire h1 {
  color: #c49c63;
  margin-top: 50px;
  text-align: center;
  font-family: "prata", serif;
  font-size: 30px;
}

.container-fluid-histoire .container-histoire .history-img-logo {
  width: 200px !important;  
}

.container-fluid-histoire .container-histoire h2 {
  width: 85%;
  color: #343535;
  font-size: 25px;
  font-family: "prata", sans-serif;
  text-align: center;
  margin: 50px 0;
}

.container-fluid-histoire .container-histoire p {
  width: 90%;
  color: #343535;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.container-fluid-histoire .container-histoire p span {
  color: #c49c63;
  font-weight: bold;
}

.container-fluid-histoire .container-histoire .col-img-histoire {
  background-image: url(./../images/MIELMONTET&HORTICULTUREPAPIER_MC_2022-6.jpg);
  /*border: 1px solid #c47a13;*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}

@media screen and (max-width: 480px) {
  .container-fluid-histoire .container-histoire h1 {
    font-size: 25px;
  }

  .container-fluid-histoire .container-histoire h3 {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
    font-size: 15px;
  }

  .container-fluid-histoire .container-histoire p {
    font-size: 12px;
  }

  .container-fluid-histoire .container-histoire .col-img-histoire {
    left: 10px !important;
    height: 200px;
    
  }

  .container-fluid-histoire .container-histoire .history-img-logo {
    width: 200px !important;  
  }
  
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .container-fluid-histoire .container-histoire .history-img-logo {
    width: 300px !important;
  }

  .container-fluid-histoire .container-histoire h1 {
    font-size: 30px;

  }

  .container-fluid-histoire .container-histoire h3 {
    font-size: 18px;
  }

  .container-fluid-histoire .container-histoire p {
    font-size: 15px;
  }
}

