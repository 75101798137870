#container-actus .logodetail {
    width: 200px;
    text-align: center;
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
  
.main-actus {
    color: #c49c63;
    margin-top: 30px;
    text-align: center;
    font-family: "prata", serif;
    font-size: 30px;
}
  


  