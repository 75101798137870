
.container-composition .div-overlay {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f5;
  width: 30%;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.container-composition .div-overlay h3 {
  width: 70%;
  margin: auto;
  line-height: 40px;
  color: #343535;
  font-size: 30px;
  font-family: "prata", sans-serif;
  text-align: center;
}

.container-composition .div-overlay .btn-commoncer {
  background-color: #c49c63;
  width: 70%;
  padding: 15px;
  border-radius: 7px;
  margin: auto;
  color: #fff;
  font-size: 20px;
  font-family: "montserrat", sans-serif;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.5s ease-in-out;
  
}

.container-composition .div-overlay .btn-commoncer:hover {
  background-color: #fff;
  color: #c49c63;
  border: 1px solid #c49c63;
}

.container-composition .div-overlay .btn-line-work {
  color: #c49c63;
  font-size: 17px;
  width: 70%;
  margin: auto;
  font-family: "montserrat", sans-serif;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}
.container-composition .div-overlay .btn-line-work:hover {
  color: #6b430a;
  transition: background-color 0.5s ease-in-out;
  transition: 0.5s;
}

.container-composition .paragraph-box h1 {
  color: #c49c63;
  width: 90%;
  margin: auto;
  font-size: 30px;
  font-family: "prata", sans-serif;
  text-align: center;
}

.container-composition .paragraph-box h2 {
  width: 90%;
  margin: auto;
  color: #343535;
  font-size: 25px;
  font-family: "prata", sans-serif;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 60px;
}

.container-composition .paragraph-box p {
  width: 90%;
  margin: auto;
  color: #343535;
  font-size: 18px;
  font-family: "montserrat", sans-serif;
  text-align: justify;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

@media screen and (max-width: 480px) {

    .container-composition .img-cover {
      height: 50vh !important;
        
    }
  
    .container-composition .div-overlay h3 {
      font-size: 18px;
    }
  
    .container-composition .div-overlay .btn-commoncer {
      font-size: 15px;
      
    }
  
    .container-composition .div-overlay .btn-line-work {
      font-size: 15px;
      margin-bottom: 15px;
    }
  
    .container-composition .paragraph-box h1 {
      font-size: 18px;
    }
  
    .container-composition .paragraph-box h2 {
      font-size: 15px;
      line-height: 30px;
    }
  
    .container-composition .paragraph-box p {
      font-size: 14px;
    }
  }

@media screen and (max-width: 768px) {

  .container-composition .img-cover {
    height: 60vh !important;
      
  }
  .container-composition .div-overlay {
    width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: -50px;
  }

  .container-composition .div-overlay h3 {
    font-size: 20px;
  }

  .container-composition .paragraph-box h1 {
    font-size: 20px;
  }

  .container-composition .paragraph-box h2 {
    font-size: 16px;
    line-height: 30px;
  }

  .container-composition .paragraph-box p {
    font-size: 15px;
  }
}

@media screen and (max-width: 1024px) {

    .container-composition .img-cover {
      height: 60vh !important;
        
    }

    .container-composition .div-overlay {
      width: 70%;
      margin: auto;
      margin-top: 10px;
      margin-bottom: -50px;
    }

    .container-composition .div-overlay h3 {
      font-size: 25px;
    }

    .container-composition .paragraph-box h1 {
      font-size: 20px;
    }

    .container-composition .paragraph-box h2 {
      font-size: 16px;
      line-height: 30px;
    }

    .container-composition .paragraph-box p {
      font-size: 15px;
    }
}

