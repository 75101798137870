@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300;400;700&display=swap");

*::selection {
  background-color: #cf9a52;
  color: #ffffff;
}

.container-panier {
  background: #ffffff;
  min-height: 100vh;
  vertical-align: middle;
  display: flex;
  font-family: sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
}

.container-panier .btn-minus,
.container-panier .btn-plus {
  width: 30px;
  background-color: #ffffff;
  color: #cf9a52;
  margin: 0px;
  height: 100%;
  font-size: 15px;
  text-align: center;
  box-shadow: none !important;
}

.container-panier .btn-minus:hover,
.container-panier .btn-plus:hover {
  transform: translateY(-2px);
  color: #2e2c2c;
  border-radius: 0px;
  transition: background-color 1s ease;
}

.container-panier .input-panier-quantity {
  width: 100%;
  margin: 0px;
  padding-left: 4px;
  padding-right: 2px;
  height: "100%";
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #cf9a52;
}
.container-panier .title {
  margin-bottom: 5vh;
}
.container-panier .card {
  margin: auto;
  max-width: 100%;
  width: 100%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  border: transparent;
}

.container-panier .price-panier {
  font-size: large;
  color: #cf9a52;
}

.container-panier .cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

.container-panier .summary {
  background-color: #d6d6d62d;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(65, 65, 65);
}

.container-panier .summary .box-img {
  padding: 0;
}

.container-panier .title b {
  font-size: 1.5rem;
}
.container-panier .main {
  margin: 0;
  padding: 2vh 0;
  width: 100%;
}
.container-panier .box-img,
.col {
  padding: 0 1vh;
}
.container-panier a {
  padding: 0 1vh;
}
.container-panier .close {
  margin-left: auto;
  font-size: 0.7rem;
}
.container-panier img {
  width: 3.5rem;
}
.container-panier .back-to-shop {
  margin-top: 4.5rem;
}
.container-panier h5 {
  margin-top: 4vh;
}
.container-panier hr {
  margin-top: 1.25rem;
}
.container-panier form {
  padding: 2vh 0;
}

.container-panier input {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}
.container-panier input:focus::-webkit-input-placeholder {
  color: transparent;
}
.container-panier .btn-checkout {
  background-color: #cf9a52;
  color: white;
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}

.container-panier .btn-checkout:hover {
  background-color: #cf9a52;
  color: white;
  transform: translateY(-2px);
}

.container-panier .btn-checkout:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: white;
  -webkit-user-select: none;
  user-select: none;
  transition: none;
}
.container-panier .btn-checkout:hover {
  background-color: #d4aa6e;
  transition: background-color 0.3s ease;
  color: white;
}

.container-panier a {
  color: black;
}
.container-panier a:hover {
  color: black;
  text-decoration: none;
}
.container-panier #code {
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.253),
      rgba(255, 255, 255, 0.185)
    ),
    url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

.col-payment {
  width: 100%;
  margin: auto;
  text-align: left;
}

.col-payment input {
  width: 100%;
  margin: 5px;
}

.col-payment label {
  margin: 5px;
}

.col-payment input::placeholder {
  font-size: 14px;
  text-align: left;
  padding-left: 5px;
}

.accordion-button {
  color: #ffffff;
  background-color: #c0750b88;
  font-family: "prata" sans-serif;
  border: none;
  box-shadow: none !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  color: #ffffff !important;
  background-color: #c0750ba8 !important;
  box-shadow: none !important;
}

.accordion-panier .Header {
  margin-left: 0px !important;
}
.accordion-panier .accordion-body {
  background-color: #f3f3f39d;
}
.accordion-panier input {
  text-align: left;
  background-color: #ffffff;
  height: 50%;
  border: 1px solid #61605e;
}
.accordion-panier input:focus,
.textarea-panier textarea:focus {
  box-shadow: none !important;
  border: 1px solid #cf9a52 !important;
  height: 50%;
  position: height 0.5s ease;
}
.accordion-panier input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.radio-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  padding-left: 0px;
}

h1 {
  margin-bottom: 20px;
}
.radio-item [type="radio"] {
  display: none;
}
.radio-item + .radio-item {
  margin-top: 15px;
}
.radio-item label {
  display: block;
  padding: 5px 50px;
  background: #f3f3f7;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  min-width: 250px;
  white-space: nowrap;
  position: relative;
  transition: 0.4s ease-in-out 0s;
  height: 35px;
}
.radio-item label:after,
.radio-item label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item label:before {
  background-image: url(./../icons/radio.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  left: 18px;
  top: calc(50%-5px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
}
.radio-item [type="radio"]:checked ~ label {
  border-color: #cf9a52;
}


.radio-item [type="radio"]:checked ~ label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.cart-empty {
  height: 80vh;
  margin-bottom: 50px;
}

.cart-empty img {
  width: 300px;
}
.cart-empty h1 {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  font-family: "prata", sans-serif;
  color: #cf9a52;
}

.cart-empty p {
  text-align: center;
  font-family: "prata", sans-serif;
  color: #cf9a52;
}

.cart-empty a {
  text-decoration: none;
  border:  1px solid #cf9a52;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #cf9a52;
  left: 50%;
  top: 110%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 40px;
 
}
.cart-empty a:hover {
  border:  1px solid #cf9a52;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  background: #cf9a52;
  color: #fff;
  transition: background-color 1s ease , color 1s ease;
}

@media (max-width: 767px) {
  .container-panier .cart {
    padding: 4vh;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
  }
  .container-panier .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .container-panier .cart {
    padding: 4vh;
    box-shadow: none;
    border: 1px solid #cf9a52;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
    width: 90%;
  }

  .container-panier .summary {
    border-bottom-left-radius: 1rem;
    border-top-right-radius: unset;
    box-shadow: none;
    border: 1px solid #cf9a52;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


